<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import size from "@/models/size.js";
import formVue from "@/components/form";
import {
  saveProcess,
  setModelValue,
  //   scrollToError,
  objectToOptions,
} from "@/_helpers/index";
import Loading from "@/components/skeleton.vue";

const sizeModel = new size();

/**
 * Starter page
 */
export default {
  components: { Layout, PageHeader, formVue, Loading },
  page: {
    title: "Data Ukuran",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Produk",
        },
        {
          text: "Ukuran",
          href: "/",
        },
        {
          text: this.$route.name,
          active: true,
        },
      ],
      isLoading: false,
      model: sizeModel.init(),
      setModel: [],
      rowCount: 4,
    };
  },
  watch: {
    "$route.params.id": function() {
      if (this.mode === "update") {
        this.getDetail();
      }
    },
  },
  computed: {
    mode: function() {
      return this.$route.params.id !== undefined ? "update" : "create";
    },
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  mounted() {
    if (this.mode === "update") {
      this.getDetail();
    }
  },
  methods: {
    getOptions(key) {
      if (this.model[key] && this.model[key].options) {
        return objectToOptions(this.model[key].options);
      } else {
        return null;
      }
    },
    getDetail() {
      this.isLoading = true;
      sizeModel
        .find(this.$route.params.id)
        .then((resp) => {
          setModelValue(this.model, resp);
          this.isLoading = false;
        })
        .catch((error) => {
          if (process.env.NODE_ENV !== "production") {
            console.error(error);
          }
        });
    },
    onSubmit() {
      this.$nextTick(() => {
        let allValid = true;
        Object.values(this.model).forEach((item, index) => {
          let childComponent = this.$refs.childComponentRef[index];
          if (
            childComponent &&
            childComponent.$v &&
            childComponent.$v.$invalid
          ) {
            allValid = false;
            return childComponent.getInvalidFeedback();
          }
        });
        if (allValid) {
          saveProcess(this.model, this.mode === "update")
            .then((model) => {
              this.isLoading = true;
              if (this.mode === "update") {
                sizeModel
                  .update(this.$route.params.id, model)
                  .then(() => {
                    this.isLoading = false;
                    this.$router.push("/produk/ukuran");
                    this.$store.dispatch(
                      "notification/success",
                      "Data berhasil diubah"
                    );
                  })
                  .catch((error) => {
                    if (process.env.NODE_ENV !== "production") {
                      console.log(error);
                    }
                    this.$store.dispatch("notification/error", error);
                  });
              } else {
                sizeModel
                  .create(model)
                  .then((resp) => {
                    this.isLoading = false;
                    this.$router.push("/produk/ukuran");
                    this.$store.dispatch(
                      "notification/success",
                      "Data berhasil disimpan"
                    );
                  })
                  .catch((error) => {
                    if (process.env.NODE_ENV !== "production") {
                      console.log(error);
                    }
                    this.$store.dispatch("notification/error", error);
                  });
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      });
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="$route.name" :items="items" />
    <b-card>
      <b-card-body v-if="!isLoading">
        <form @submit.prevent="onSubmit">
          <form-vue
            v-for="(m, index) in model"
            :key="index"
            :mode="mode"
            :type="m.type"
            :title="m.title"
            :value="m.value"
            :label="typeof m.label === 'string' ? m.label : null"
            :options="getOptions(index)"
            v-model="m.value"
            :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null"
            :id="index"
            :min-len="m.minLen"
            :max-len="m.maxLen"
            :validation-rules="m.validationRules"
            :invalid-feedback="m.invalidFeedback"
            ref="childComponentRef"
          />
          <b-button type="submit" variant="primary" class="mt-3 float-right"
            >Simpan</b-button
          >
        </form>
      </b-card-body>
      <b-card-body v-else>
        <div v-for="row in rowCount" :key="row" class="my-4">
          <loading class="my-3" width="35%" height="2rem" borderRadius="7px" />
          <loading width="100%" height="2rem" borderRadius="7px" />
        </div>
        <loading class="mt-4" width="10%" height="2rem" borderRadius="7px" />
      </b-card-body>
    </b-card>
  </Layout>
</template>
